import axios from "axios";
import { baseUrl } from "../helpers";

export const sendCV = (companyName, location, id, CV, fileName) => {
  return axios.post(
    `${baseUrl}/${companyName}/${location}/apply/cv/${id}`,
    CV,
    {
      headers: { "Content-Type": "multipart/form-data", "file-name": fileName }
    }
  );
};
