import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next'
import linkedin from "../assets/img/linkedIn.svg";
import facebook from "../assets/img/fb.svg";
import PhotoPopup from "./PhotoPopup";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import TitleBlock from "./TitleBlock";
import FormBlock from "./FormBlock";
import { facebookAppId } from "../helpers";
import TermsPopup from "./TermsPopup";
import EmptyPhoto from "../assets/img/empty_photo.svg";

class ApplyForm extends Component {
  state = {
    showPopup: false,
    showTerms: false,
    showSocialUploads: false
  };

  callPopup = () => {
    this.setState({ showPopup: true });
    document.documentElement.classList.add("fixed");
  };

  closePopup = () => {
    this.setState({ showPopup: false });
    document.documentElement.classList.remove("fixed");
  };

  callTerms = () => {
    this.setState({ showTerms: true });
    document.documentElement.classList.add("fixed");
  };

  closeTerms = () => {
    this.setState({ showTerms: false });
    document.documentElement.classList.remove("fixed");
  };

  callSocialUploads = () => {
    this.setState({ showSocialUploads: true });
  };

  socialAuthHandler = e => {
    if (!e.target.checked) {
      e.preventDefault();
      e.target.checked = true;
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    localStorage.removeItem("successNumber");
    localStorage.removeItem("uploadedCV");
    localStorage.removeItem("visitedCareer");
    localStorage.removeItem("contactedHR");
  }

  render() {
    const {
      fields,
      firstStepFormData,
      firstStepFormHandler,
      description,
      facebookAvatar,
      userInfo,
      linkedInStatus,
      facebookStatus,
      showLinkedInPhoto,
      showFacebookPhoto,
      showUploadedImage,
      selectPopupPhoto,
      setPopupPhoto,
      selectedPhoto,
      responseFacebook,
      photosList,
      selectedSN,
      answer,
      handleConnectLinkedInClick,
      t
    } = this.props;

    console.log(userInfo);

    return (
      <div className="form-screen">
        <div className="container">
          <div
            className="job-screen__title form-screen__title"
            style={
              description.background
                ? {
                    background:
                      "url(" +
                      description.background +
                      ") no-repeat top center",
                    backgroundSize: "cover"
                  }
                : {}
            }
          >
            <TitleBlock description={description} />
          </div>
          <div className="form-screen__form-section">
            <div className="form-screen__progress step-1">
              <ul>
                <li className="completed" />
                <li className="active" />
                <li />
              </ul>
            </div>
            <div className="first-screen screen active" data-index="2">
              <div className="form-screen__blocks-group">
                <div
                  className="form-screen__block change-photo"
                  onClick={this.callPopup}
                >
                  <div className="change-photo__image">
                    <img src={selectedPhoto || EmptyPhoto} alt="" />
                  </div>
                  <div className="form-screen__change">
                    <span>
                      {selectedSN
                        ? t('apply_form.using_photo', {selectedSN})
                        : t('apply_form.select_photo')}
                    </span>
                    <button className="alt">{t('apply_form.change_profile_photo')}</button>
                  </div>
                </div>
                <div className="form-screen__block apply-via">
                  <h2>{t('apply_form.connect_with')}</h2>
                  <label
                    onClick={handleConnectLinkedInClick}
                    className={linkedInStatus ? "checked" : ""}
                  >
                    <img src={linkedin} alt="" />
                    <input
                      className="radio linkedin"
                      type="checkbox"
                      name="apply-via"
                      value="linkedin"
                      onChange={this.socialAuthHandler}
                      checked={linkedInStatus}
                    />
                    <span className="radiomark" />
                  </label>
                  <FacebookLogin
                    disableMobileRedirect={true}
                    appId={facebookAppId}
                    autoLoad={JSON.parse(localStorage.getItem("facebookAuth"))}
                    fields="picture.width(100).height(100),first_name,last_name,photos,email"
                    scope="public_profile,email,user_photos"
                    callback={responseFacebook}
                    render={renderProps => (
                      <label
                        onClick={renderProps.onClick}
                        className={facebookStatus ? "checked" : ""}
                      >
                        <img src={facebook} alt="" />
                        <input
                          className="radio facebook"
                          type="checkbox"
                          name="apply-via"
                          value="facebook"
                          onChange={this.socialAuthHandler}
                          checked={facebookStatus}
                        />
                        <span className="radiomark" />
                      </label>
                    )}
                    cssClass="facebook"
                  />
                </div>
              </div>
              <div className="form-screen__block fill-in">
                <h2>{t('apply_form.form_title')}</h2>
                <FormBlock
                  key={
                    linkedInStatus
                      ? "linkedInData"
                      : facebookStatus
                      ? "facebookData"
                      : "manualData"
                  }
                  answer={answer}
                  secondStepExist={this.props.secondStepExist}
                  firstStepFormData={firstStepFormData}
                  savedFormData={this.props.savedFormData}
                  fields={fields}
                  userInfo={userInfo}
                  selectedPhoto={selectedPhoto}
                  selectPopupPhoto={selectPopupPhoto}
                  setPopupPhoto={setPopupPhoto}
                  submitHandler={firstStepFormHandler}
                  callPopup={this.callTerms}
                  CVFileName={this.props.CVFileName}
                  setCVName={this.props.setCVName}
                  step={1}
                />
              </div>
            </div>
          </div>
          <PhotoPopup
            active={this.state.showPopup}
            showSocialUploads={this.state.showSocialUploads}
            callSocialUploads={this.callSocialUploads}
            closePopup={this.closePopup}
            responseFacebook={responseFacebook}
            selectedPhoto={selectedPhoto}
            selectPopupPhoto={selectPopupPhoto}
            setPopupPhoto={setPopupPhoto}
            linkedInPhoto={userInfo && userInfo.avatar}
            facebookPhoto={facebookAvatar && facebookAvatar.data.url}
            linkedInStatus={linkedInStatus}
            facebookStatus={facebookStatus}
            handleConnectLinkedInClick={handleConnectLinkedInClick}
            photosList={photosList}
            showLinkedInPhoto={showLinkedInPhoto}
            showFacebookPhoto={showFacebookPhoto}
            showUploadedImage={showUploadedImage}
            wrapperClasses={""}
          />
        </div>
        <TermsPopup
          closePopup={this.closeTerms}
          active={this.state.showTerms}
        />
      </div>
    );
  }
}

export default withRouter(withTranslation()(ApplyForm));
