import axios from "axios";
import { baseUrl } from "../helpers";

export async function getJobDescriptionApi(
  dispatch,
  successType,
  failType,
  url
) {
  try {
    const response = await axios.get(`${baseUrl}${url}`);
    dispatch({
      type: successType,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: failType,
      payload: error
    });
  }
}
