import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next'
import FacebookLogin from "react-facebook-login";
import PropTypes from "prop-types";
import TitleBlock from "./TitleBlock";
import { facebookAppId } from "../helpers";

class JobDescription extends Component {
  state = {
    revealed: false,
    descriptionHeight: 88,
    descriptionWidth: '100%'
  };

  linkedInAuthHandler = () => {
    this.props.handleConnectLinkedInClick();
    this.props.goToForm();
  };

  expandText = () => {
    const textHeight = document.getElementsByClassName('condensed')[0].offsetHeight;
    const textWidth = document.getElementsByClassName('condensed')[0].offsetWidth;
    this.setState({
      revealed: !this.state.revealed,
      descriptionHeight: textHeight === this.state.descriptionHeight ? 88 : textHeight,
      descriptionWidth: textWidth === this.state.descriptionWidth ? '100%' : textWidth
    });
  };

  render() {
    const { description, goToForm, t } = this.props;
    return (
      <div className={`job-screen ${this.state.revealed ? "revealed" : ""}`}>
        <div
          className="job-screen__background"
          style={
            description.background
              ? {
                  background:
                    "url(" + description.background + ") no-repeat top center",
                  backgroundSize: "cover"
                }
              : {}
          }
        />
        <div className="container">
          <div className="content">
            <div
              className="job-screen__title"
              style={
                description.background
                  ? {
                      background:
                        "url(" +
                        description.background +
                        ") no-repeat top center",
                      backgroundSize: "cover"
                    }
                  : {}
              }
            >
              <TitleBlock description={description} />
            </div>
            <div className="job-screen__content">
              <div className="job-screen__description">
                <h2>{t('description.role_description')}</h2>
                {description.text && (
                  <div className="text-block" style={{width:this.state.descriptionWidth}}>
                    <p className="shaved" style={{maxHeight: this.state.descriptionHeight}}>{description.text}</p>
                    <p className="condensed">{description.text}</p>
                  </div>
                )}
                <button className="alt show-more" onClick={this.expandText}>
                  {this.state.revealed ? t('description.show_less') : t('description.show_more')}
                </button>
              </div>
              <div className="job-screen__benefits">
                <h2>{t('description.benefits')}</h2>
                {description.benefits && (
                  <ul>
                    {description.benefits.map((el, index) => (
                      <li key={index}>{el}</li>
                    ))}
                  </ul>
                )}
              </div>
              <ul className="job-screen__apply-buttons">
                <li>
                  <button
                    className="linkedin"
                    onClick={this.linkedInAuthHandler}
                  >
                    {t('description.apply_with_linkedIn')}
                  </button>
                </li>
                <li>
                  <FacebookLogin
                    disableMobileRedirect={true}
                    appId={facebookAppId}
                    fields="picture.width(100).height(100),first_name,last_name,photos,email"
                    scope="public_profile,email,user_photos"
                    callback={this.props.responseFacebook}
                    textButton={t('description.apply_with_facebook')}
                    cssClass="facebook"
                    onClick={goToForm}
                  />
                </li>
                <li>
                  <button className="manual" onClick={goToForm}>
                    {t('description.apply_without_networks')}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

JobDescription.propTypes = {
  description: PropTypes.shape({
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    schedule: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    benefits: PropTypes.array.isRequired
  })
};

export default withRouter(withTranslation()(JobDescription));
