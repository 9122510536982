import React, { Component } from "react";
import { withTranslation } from 'react-i18next'
import DBIco from "../assets/img/dropbox_ico.svg";
import GDIco from "../assets/img/drive_ico.svg";
import storageIco from "../assets/img/upload.svg";
import DropboxChooser from "react-dropbox-chooser";
import GooglePicker from "react-google-picker";
import { sendCV } from "../services/sendCV";
import { getGoogleFile } from "../services/googleFile";
import { dropBoxId, googleDeveloperKey, googleClientId } from "../helpers";

class CVPopup extends Component {
  state = {
    GDAuthToken: "",
    localFile: "",
    fileError: false
  };

  onDBSuccess = async file => {
    const {
      formUploadingCV,
      companyName,
      id,
      cvUploadHandler,
      closePopup
    } = this.props;
    cvUploadHandler
      ? sendCV(companyName, id, file[0].link, file[0].name).then(() => {
          cvUploadHandler();
          closePopup();
        })
      : formUploadingCV(file[0].link, file[0].name);
    this.props.setCVName(file[0].name);
  };

  onGDSuccess = data => {
    const {
      formUploadingCV,
      companyName,
      id,
      cvUploadHandler,
      closePopup
    } = this.props;
    data.docs &&
      getGoogleFile(data.docs[0].id, this.state.GDAuthToken).then(res => {
        cvUploadHandler
          ? sendCV(
              companyName,
              id,
              res.data.alternateLink,
              res.data.title
            ).then(() => {
              cvUploadHandler();
              closePopup();
            })
          : formUploadingCV(res.data.alternateLink, res.data.title);
        this.props.setCVName(res.data.title);
      });
  };

  localFileHandler = e => {
    const fileTypes = ["pdf", "doc", "docx", "png", "jpg"];
    const reader = new FileReader();
    const {
      formUploadingCV,
      companyName,
      city,
      id,
      cvUploadHandler,
      closePopup
    } = this.props;
    const file = e.target.files[0];
    const fileName = file.name;
    const availableSize = 5000000;

    const extension = fileName
      .split(".")
      .pop()
      .toLowerCase();

    const isSuccess =
      fileTypes.indexOf(extension) > -1 && file.size <= availableSize;

    if (isSuccess) {
      reader.onload = e => {
        const formData = new FormData();
        formData.append("CV", e.target.result);
        cvUploadHandler
          ? sendCV(companyName, city, id, formData, fileName).then(() => {
              cvUploadHandler();
              closePopup();
            })
          : formUploadingCV(e.target.result, fileName);
        this.props.setCVName(fileName);
      };

      reader.readAsDataURL(file);
    } else {
      this.setState({ fileError: true });
    }
  };

  errorHandlerCV = () => {
    this.setState({ fileError: false });
  };

  render() {
    const { active, closePopup, t } = this.props;
    return (
      <div className={`popup-cv popup ${active ? "active" : ""}`}>
        <div className="popup__overlay" onClick={closePopup} />
        <button className="popup__close" onClick={closePopup} type="button" />
        <div className="popup__content">
          {!this.state.fileError ? (
            <div className="popup-cv__content">
              <h3 className="popup__title">{t('CV_popup.title')}</h3>
              <div className="popup__upload show">
                <DropboxChooser
                  appKey={dropBoxId}
                  success={file => this.onDBSuccess(file)}
                  multiselect={false}
                  extensions={[".pdf", ".doc", ".docx", ".png", ".jpg"]}
                >
                  <div className="popup__upload-item social social-db">
                    <div className="popup__upload-icon">
                      <img src={DBIco} alt="" />
                    </div>
                    <p className="popup__upload-text">{t('CV_popup.upload_from_network', {network: "Dropbox"})}</p>
                  </div>
                </DropboxChooser>
                <GooglePicker
                  clientId={googleClientId}
                  developerKey={googleDeveloperKey}
                  scope={["https://www.googleapis.com/auth/drive.file"]}
                  onChange={data => this.onGDSuccess(data)}
                  onAuthenticate={token =>
                    this.setState({ GDAuthToken: token })
                  }
                  onAuthFailed={data => console.log("on auth failed:", data)}
                  navHidden={true}
                  authImmediate={false}
                  mimeTypes={[
                    "image/png",
                    "image/jpg",
                    "application/pdf",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "application/vnd.google-apps.document"
                  ]}
                  viewId={"DOCS"}
                >
                  <div className="popup__upload-item social social-gd">
                    <div className="popup__upload-icon">
                      <img src={GDIco} alt="" />
                    </div>
                    <p className="popup__upload-text">
                      {t('CV_popup.upload_from_network', {network: "GoogleDrive"})}
                    </p>
                  </div>
                </GooglePicker>
                <label className="popup__upload-item">
                  <input
                    type="file"
                    name="local_photo"
                    onChange={this.localFileHandler}
                    accept=".pdf, .doc., .docx, .png, .jpg"
                    size="5000000"
                    value={this.state.localFile}
                  />
                  <div className="popup__upload-icon">
                    <img src={storageIco} alt="" />
                  </div>
                  <p className="popup__upload-text">{t('CV_popup.upload_from_local')}</p>
                </label>
              </div>
            </div>
          ) : (
            <div className="popup-cv__error">
              <h3 className="popup__title">{t('CV_popup.error_title')}</h3>
              <p className="error__text">{t('CV_popup.allowed_formats')}</p>
              <button
                type="button"
                className="popup__tab-button"
                onClick={this.errorHandlerCV}
              >
                {t('CV_popup.upload_another')}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(CVPopup);
