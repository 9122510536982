import React from "react";
import { useTranslation } from 'react-i18next'
import { Field } from "formik";
import DBIco from "../assets/img/dropbox_ico.svg";
import GDIco from "../assets/img/drive_ico.svg";
import storageIco from "../assets/img/upload.svg";

const CVField = ({
  required,
  CVFileName,
  CVFile,
  callPopup,
  savedCVFileName,
  name,
  description
}) => {
  const { t } = useTranslation();
  return (
    <label className="form-group CVField">
      <h3>
        {t('form.CV_field_title')}
        <span>{required ? "required" : "optional"}</span>
      </h3>
      {CVFileName || CVFile ? (
        <span className="uploadedCV">
        <span className="fileName" onClick={callPopup}>
          {savedCVFileName || CVFileName}
          <span className="checkIco" />
        </span>
        <span className="hint">
          {t('form.CV_field_hint')}
        </span>
      </span>
      ) : (
        <span className="callCV" onClick={callPopup}>
        <h4>{t('form.CV_field_upload')}</h4>
        <span className="social-icons">
          <img src={DBIco} alt="" />
          <img src={GDIco} alt="" />
          <img src={storageIco} alt="" />
        </span>
      </span>
      )}
      <Field
        name={name}
        placeholder={description}
        type="text"
        className="hidden"
        value={CVFile}
      />
    </label>
  );
}

export default CVField;
