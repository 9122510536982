import { getJobDescriptionApi } from "../services/JobDescription";
export const GET_DESCRIPTION_REQUEST = "GET_DESCRIPTION_REQUEST";
export const GET_DESCRIPTION_SUCCESS = "GET_DESCRIPTION_SUCCESS";
export const GET_DESCRIPTION_FAIL = "GET_DESCRIPTION_FAIL";

export function getDescription(url) {
  return dispatch => {
    dispatch({
      type: GET_DESCRIPTION_REQUEST
    });

    getJobDescriptionApi(
      dispatch,
      GET_DESCRIPTION_SUCCESS,
      GET_DESCRIPTION_FAIL,
      url
    );
  };
}
