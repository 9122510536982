import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AnimatedNumber from "react-animated-number";
import CVPopup from "./CVPopup";
import PhotoPopup from "./PhotoPopup";
import TitleBlock from "./TitleBlock";
import EmptyPhoto from "../assets/img/empty_photo.svg";
import { withTranslation } from 'react-i18next'

class Confirmation extends Component {
  state = {
    showPopup: false,
    showPhotoPopup: false,
    showSocialUploads: false,
    successNumber: 70,
    uploadedCV: null,
    visitedCareer: null,
    contactedHR: null,
    userName: ""
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { jobData } = this.props.params;
    const savedId = JSON.parse(localStorage.getItem("applicationId"));
    const currentId = jobData.slice(jobData.lastIndexOf("-") + 1);

    !localStorage.getItem("formId") ||
      (savedId !== currentId && this.props.goToForm());

    this.setState({
      successNumber: JSON.parse(localStorage.getItem("successNumber")) || 70,
      uploadedCV: JSON.parse(localStorage.getItem("uploadedCV")),
      visitedCareer: JSON.parse(localStorage.getItem("visitedCareer")),
      contactedHR: JSON.parse(localStorage.getItem("contactedHR"))
    });

    const {
      selectedPhoto,
      selectPopupPhoto,
      setPopupPhoto,
      answer
    } = this.props;

    let userName = "";

    if (answer) {
      answer.forEach(item => {
        item.name === "avatar" &&
          !selectedPhoto &&
          selectPopupPhoto("") &&
          setPopupPhoto(item.value);
        userName += item.name === "name" ? item.value : "";
      });
      this.setState({ userName });
    }
  }

  callPopup = e => {
    e.preventDefault();
    this.setState({ showPopup: true });
  };

  closePopup = () => {
    this.setState({ showPopup: false });
  };

  careerHandler = () => {
    if (!this.state.visitedCareer) {
      this.setState({ visitedCareer: true });
      localStorage.setItem("visitedCareer", "true");
      this.increaseSuccess();
    }
  };

  contactHandler = () => {
    if (!this.state.contactedHR) {
      this.setState({ contactedHR: true });
      localStorage.setItem("contactedHR", "true");
      this.increaseSuccess();
    }
  };

  cvUploadHandler = () => {
    if (!this.state.uploadedCV) {
      this.setState({ uploadedCV: true });
      localStorage.setItem("uploadedCV", "true");
      setTimeout(() => {
        this.increaseSuccess();
      }, 500);
    }
  };

  increaseSuccess = () => {
    const successNumber = this.state.successNumber + 10;
    this.setState({ successNumber });
    localStorage.setItem("successNumber", successNumber);
  };

  callPhotoPopup = () => {
    this.setState({
      showPhotoPopup: true
    });
  };

  closePhotoPopup = () => {
    this.setState({
      showPhotoPopup: false
    });
  };

  callSocialUploads = () => {
    this.setState({ showSocialUploads: true });
  };

  render() {
    const {
      description,
      selectedPhoto,
      userName,
      responseFacebook,
      userInfo,
      facebookAvatar,
      linkedInStatus,
      facebookStatus,
      handleConnectLinkedInClick,
      photosList,
      showLinkedInPhoto,
      showFacebookPhoto,
      showUploadedImage,
      selectPopupPhoto,
      setPopupPhoto,
      t
    } = this.props;

    const { location } = this.props.params;

    return (
      <div className="applied-screen">
        <div className="container">
          <div className="job-screen__title applied-screen__title">
            <span>{t('confirmation.title')}</span>
            <div className="confirmation-animated-header">
              <TitleBlock description={description} />
            </div>
          </div>

          <div className="profile">
            <div className="profile__info">
              <div className="profile__info-wrapper">
                <div
                  className={`photo${selectedPhoto ? " withPhoto" : ""}`}
                  onClick={this.callPhotoPopup}
                >
                  <div className="photo__container">
                    <img src={selectedPhoto || EmptyPhoto} alt="" />
                  </div>
                  {EmptyPhoto && <div className="plus" />}
                </div>
                <h2 className="name">{userName || this.state.userName}</h2>
                <span className="percentage">
                  <AnimatedNumber
                    component="i"
                    value={this.state.successNumber}
                    stepPrecision={0}
                    formatValue={n => `${n}%`}
                    duration={400}
                  />
                </span>
                <span className="strength">{t('confirmation.profile_strength')}</span>
              </div>
            </div>
            <div className="profile__improve">
              <div className="profile__improve-wrapper">
                <h2>{t('confirmation.improve_title')}</h2>
                <ul>
                  <li>
                    <a
                      href="/"
                      onClick={this.callPopup}
                      className={this.state.uploadedCV ? "completed" : ""}
                    >
                      <span className="percentage">+10%</span>
                      <div className="profile__improve-info">
                        <h3>{t('confirmation.upload_cv')}</h3>
                        <span>
                          {this.state.uploadedCV
                            ? t('confirmation.upload_new_cv_button')
                            : t('confirmation.upload_cv_button')}
                        </span>
                      </div>
                      <span className="upload" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={description.career_page}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={this.careerHandler}
                      className={this.state.visitedCareer ? "completed" : ""}
                    >
                      <span className="percentage">+10%</span>
                      <div className="profile__improve-info">
                        <h3>{t('confirmation.read_about_employer')}</h3>
                        <span className="career__link">{t('confirmation.career_link')}</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      href={`mailto:${description.hr_email}`}
                      onClick={this.contactHandler}
                      className={this.state.contactedHR ? "completed" : ""}
                    >
                      <span className="percentage">+10%</span>
                      <div className="profile__improve-info">
                        <h3>{t('confirmation.contact_hr')}</h3>
                        <span>{description.hr_name}</span>
                      </div>
                    </a>
                  </li>
                </ul>
                <div className="profile__improve-contact">
                  <img src={description.hr_photo} alt="" />
                  <div className="links">
                    <a
                      href={`mailto:${description.hr_email}`}
                      className="email"
                    >
                      {description.hr_email}
                    </a>
                    <a href={`tel:${description.hr_phone}`} className="tel">
                      {description.hr_phone}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CVPopup
            active={this.state.showPopup}
            id={JSON.parse(localStorage.getItem("formId"))}
            companyName={this.props.params.company}
            city={location}
            cvUploadHandler={this.cvUploadHandler}
            closePopup={this.closePopup}
          />
          <PhotoPopup
            active={this.state.showPhotoPopup}
            id={JSON.parse(localStorage.getItem("formId"))}
            companyName={this.props.params.company}
            city={location}
            showSocialUploads={this.state.showSocialUploads}
            callSocialUploads={this.callSocialUploads}
            closePopup={this.closePhotoPopup}
            responseFacebook={responseFacebook}
            selectedPhoto={selectedPhoto}
            selectPopupPhoto={selectPopupPhoto}
            setPopupPhoto={setPopupPhoto}
            linkedInPhoto={userInfo && userInfo.avatar}
            facebookPhoto={facebookAvatar && facebookAvatar.data.url}
            linkedInStatus={linkedInStatus}
            facebookStatus={facebookStatus}
            handleConnectLinkedInClick={handleConnectLinkedInClick}
            photosList={photosList}
            showLinkedInPhoto={showLinkedInPhoto}
            showFacebookPhoto={showFacebookPhoto}
            showUploadedImage={showUploadedImage}
            allowAvatarUpdating={true}
            wrapperClasses={"popup-confirmation"}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(Confirmation));
