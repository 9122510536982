import React from "react";
import { useTranslation } from 'react-i18next'

const NotFound = ({ goBack }) => {
  const { t } = useTranslation();
  return (
    <div className="notFound">
      <h1 className="notFound-title">404</h1>
      <h3 className="notFound-heading">{t('not_found.title')}</h3>
      <p className="notFound-text">
        {t('not_found.text')}
      </p>
      <button className="notFound-button" type="button" onClick={goBack}>
        {t('not_found.back')}
      </button>
      <a href="#" className="notFound-link">
        {t('not_found.support')}
      </a>
    </div>
  );
}

export default NotFound;
