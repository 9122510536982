import React, { Component } from "react";
import TitleBlock from "./TitleBlock";
import FormBlock from "./FormBlock";
import EmptyPhoto from "../assets/img/empty_photo.svg";
import { withTranslation } from 'react-i18next'

class ApplyFormStep2 extends Component {
  state = {
    showPopup: false
  };

  callPopup = () => {
    this.setState({ showPopup: true });
  };

  closePopup = () => {
    this.setState({ showPopup: false });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      description,
      fields,
      userInfo,
      sendForm,
      selectedPhoto,
      selectPopupPhoto,
      setPopupPhoto,
      answer,
      userName,
      goToForm,
      t
    } = this.props;

    return (
      <div className="form-screen">
        <div className="container">
          <div
            className="job-screen__title form-screen__title"
            style={
              description.background
                ? {
                    background:
                      "url(" +
                      description.background +
                      ") no-repeat top center",
                    backgroundSize: "cover"
                  }
                : {}
            }
          >
            <TitleBlock description={description} />
          </div>
          <div className="form-screen__form-section">
            <div className="form-screen__progress step-2">
              <ul>
                <li className="completed" />
                <li className="active" />
                <li />
              </ul>
            </div>
            <div className="second-screen screen active" data-index="3">
              <div
                className="form-screen__block change-photo"
                onClick={goToForm}
              >
                <div className="change-photo__image">
                  <img src={selectedPhoto || EmptyPhoto} alt="" />
                </div>
                <div className="form-screen__change">
                  <span>{userName}</span>
                  <button className="alt">{t('apply_form_step_2.change_profile_info')}</button>
                </div>
              </div>
              <FormBlock
                answer={answer}
                fields={fields}
                userInfo={userInfo}
                selectedPhoto={selectedPhoto}
                selectPopupPhoto={selectPopupPhoto}
                setPopupPhoto={setPopupPhoto}
                submitHandler={sendForm}
                step={2}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ApplyFormStep2);
