import { getAnswerApi } from "../services/Answer";
export const GET_ANSWER_REQUEST = "GET_ANSWER_REQUEST";
export const GET_ANSWER_SUCCESS = "GET_ANSWER_SUCCESS";
export const GET_ANSWER_FAIL = "GET_ANSWER_FAIL";

export function getAnswer(companyName, location, currentId) {
  return dispatch => {
    dispatch({
      type: GET_ANSWER_REQUEST
    });

    getAnswerApi(
      dispatch,
      GET_ANSWER_SUCCESS,
      GET_ANSWER_FAIL,
      companyName,
      location,
      currentId
    );
  };
}
