import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next'
import { Formik, Form } from "formik";
import * as Yup from "yup";
import OptionsField from "./OptionsField";
import CVField from "./CVField";
import TextField from "./TextField";
import TextAreaField from "./TextAreaField";
import CVPopup from "./CVPopup";

class FormBlock extends Component {
  state = {
    initValsObj: null,
    SignupSchema: null,
    userInfo: null,
    SNFilledFieldsTouched: false,
    showPopup: false,
    CVFile: "",
    CVFileName: ""
  };

  getInputClasses = (errors, touched, field) => {
    return touched[field] ? (errors[field] ? "not-valid" : "validated") : "";
  };

  touchFields(setTouched) {
    this.setState({ SNFilledFieldsTouched: true });
    setTouched({ name: true, email: true });
  }

  callPopup = e => {
    e.preventDefault();
    this.setState({ showPopup: true });
  };

  closePopup = () => {
    this.setState({ showPopup: false });
  };

  formUploadingCV = (file, name) => {
    this.setState({
      CVFile: file,
      CVFileName: name
    });
    this.closePopup();
  };

  componentDidMount() {
    const {
      fields,
      selectedPhoto,
      selectPopupPhoto,
      setPopupPhoto,
      answer,
      userInfo,
      firstStepFormData,
      t
    } = this.props;

    const savedData = answer || firstStepFormData;

    const validateObj = {};
    const initValsObj = {};

    fields.forEach(el => {
      const pattern = new RegExp(
        el.validation.pattern,
        el.validation.pattern_flags
      );

      if (savedData) {
        savedData.forEach(item => {
          item.name === "avatar" &&
            !selectedPhoto &&
            selectPopupPhoto("") &&
            setPopupPhoto(item.value);

          if (!el.options || el.options.length <= 0) {
            if (el.name === item.name) {
              initValsObj[el.name] = item.value;
            }
          } else {
            if (el.groupName === item.name) {
              initValsObj[el.groupName] = item.value;
            }
          }
        });
      } else {
        if (!el.options || el.options.length <= 0) {
          initValsObj[el.name] =
            userInfo && el.name === "name"
              ? userInfo.first_name + " " + userInfo.last_name
              : el.name === "email"
              ? userInfo && userInfo.email ? userInfo.email : ""
              : "";
        } else {
          initValsObj[el.groupName] = el.type === "checkbox" ? [] : "";
        }
      }
      validateObj[el.name] = Yup.string()
        .trim()
        .required(el.validation.required && t('form.validation.required', {name: el.description}))
        .matches(pattern, t('form.validation.matches', {name: el.description}))
        .min(
          el.validation.min_length || 0,
          t('form.validation.min_length', {name: el.description, length: el.validation.min_length})
        )
        .max(
          el.validation.max_length > 0 ? el.validation.max_length : 1000000,
          t('form.validation.max_length', {name: el.description, length: el.validation.max_length})
        );
    });
    this.setState({
      SignupSchema: Yup.object().shape(validateObj),
      initValsObj
    });
  }

  render() {
    const {
      fields,
      submitHandler,
      selectedPhoto,
      answer,
      firstStepFormData,
      step,
      CVFileName,
      t
    } = this.props;

    const {
      initValsObj,
      SignupSchema,
      SNFilledFieldsTouched,
      CVFile
    } = this.state;

    return (
      initValsObj &&
      SignupSchema && (
        <Formik
          enableReinitialize
          initialValues={initValsObj}
          validationSchema={SignupSchema}
          onSubmit={values => {
            const formData = answer || firstStepFormData || [];
            fields.map(item => {
              const obj = {};
              obj.name = item.options.length > 0 ? item.groupName : item.name;
              obj.type = item.type;
              obj.value =
                item.options.length > 0
                  ? values[item.groupName]
                  : values[item.name];
              if (item.name === "avatar") obj.value = selectedPhoto;
              if (item.name === "CV") obj.value = CVFile;
              return formData.push(obj);
            });
            if (CVFileName || CVFile) {
              localStorage.setItem("uploadedCV", "true");
              localStorage.setItem("successNumber", "80");
            }
            submitHandler(formData);
          }}
        >
          {({ errors, touched, isValid, setTouched }) => {
            initValsObj.name &&
              !SNFilledFieldsTouched &&
              this.touchFields(setTouched);
            return (
              <Form className={answer || firstStepFormData ? "saved-data" : ""}>
                {fields &&
                  fields.map((el, index) =>
                    el.options.length > 0 ? (
                      <OptionsField
                        key={index}
                        type={el.type}
                        description={el.description}
                        options={el.options}
                        groupName={el.groupName}
                        errors={errors}
                        touched={touched}
                      />
                    ) : el.type === "textarea" ? (
                      <TextAreaField
                        key={index}
                        getInputClasses={this.getInputClasses}
                        errors={errors}
                        touched={touched}
                        name={el.name}
                        required={el.validation.required}
                        description={el.description}
                        type={el.type}
                      />
                    ) : el.name === "CV" ? (
                      <CVField
                        key={index}
                        required={el.validation.required}
                        CVFileName={CVFileName}
                        CVFile={CVFile}
                        callPopup={this.callPopup}
                        savedCVFileName={this.state.CVFileName}
                        name={el.name}
                        description={el.description}
                      />
                    ) : (
                      <TextField
                        key={index}
                        getInputClasses={this.getInputClasses}
                        errors={errors}
                        touched={touched}
                        name={el.name}
                        required={el.validation.required}
                        description={el.description}
                        type={el.type}
                      />
                    )
                  )}
                {step === 1 ? (
                  <div>
                    <button
                      type="submit"
                      className={`form-screen__button ${
                        SNFilledFieldsTouched
                          ? Object.keys(errors).length !== 0 &&
                            "form-screen__button--disabled"
                          : !isValid && "form-screen__button--disabled"
                      }`}
                    >
                      {this.props.secondStepExist
                        ? t('form.buttons.continue')
                        : t('form.buttons.submit')}
                    </button>
                    <button
                      type="button"
                      onClick={this.props.callPopup}
                      className="form-screen__tos"
                    >{t('form.buttons.terms_hint')}</button>
                    <CVPopup
                      active={this.state.showPopup}
                      formUploadingCV={this.formUploadingCV}
                      closePopup={this.closePopup}
                      setCVName={this.props.setCVName}
                    />
                  </div>
                ) : (
                  <div>
                    <button
                      type="submit"
                      className={`form-screen__button ${
                        SNFilledFieldsTouched
                          ? Object.keys(errors).length !== 0 &&
                          "form-screen__button--disabled"
                          : !isValid && "form-screen__button--disabled"
                        }`}
                    >{t('form.buttons.submit')}</button>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      )
    );
  }
}

export default withRouter(withTranslation()(FormBlock));
