import {
  GET_ANSWER_REQUEST,
  GET_ANSWER_SUCCESS,
  GET_ANSWER_FAIL
} from "../actions/AnswerActions";

const initialState = {
  data: "",
  isFetching: true,
  error: ""
};

export function answerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ANSWER_REQUEST:
      return { ...state, isFetching: true };

    case GET_ANSWER_SUCCESS:
      return { ...state, data: action.payload, isFetching: false };

    case GET_ANSWER_FAIL:
      return { ...state, error: action.payload, data: null, isFetching: false };

    default:
      return state;
  }
}
