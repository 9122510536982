import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Container from "./containers/Container";
import LinkedInPopup from "./components/LinkedInPopup";

class ApplyForm extends Component {
  render() {
    return (
      <Router>
        <div className="overflow-wrapper">
          <Route path="/:company/:location/:jobData" component={Container} />
          <Route path={`/linkedIn`} component={LinkedInPopup} />
        </div>
      </Router>
    );
  }
}

export default ApplyForm;
