import React from "react";

const FormError = ({ errors, touched, field }) =>
  errors[field] && touched[field] ? (
    <p className="form-error">{errors[field]}</p>
  ) : (
    ""
  );

export default FormError;
