import React from "react";

const TitleBlock = ({ description }) => (
  <>
    {description.logo && (
      <div
        className="job-screen__logo"
        style={
          description.logo_background_color
            ? {
                backgroundColor: description.logo_background_color
              }
            : {}
        }
      >
        <img src={description.logo} alt="logo" />
      </div>
    )}
    {description.title && <h1>{description.title}</h1>}
    <ul className="job-screen__job-info">
      {description.name && <li>{description.name}</li>}
      {description.address && <li>{description.address}</li>}
      {description.schedule && <li>{description.schedule}</li>}
    </ul>
  </>
);

export default TitleBlock;
