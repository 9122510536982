import axios from "axios";
import { baseUrl } from "../helpers";

const updateAvatar = (companyName, location, id, avatar, fileName) => {
  return axios.put(
    `${baseUrl}/${companyName}/${location}/apply/avatar/${id}`,
    avatar,
    {
      headers: { "Content-Type": "multipart/form-data", "file-name": fileName }
    }
  );
};

export default updateAvatar;
