import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_en from './translations/en/common'

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translations: common_en
      }
    },
    ns: ["translations"],
    defaultNS: "translations",
    react: {
      wait: true
    }
  });

export default i18n;
