import React from "react";
import { Field } from "formik";
import FormError from "./FormError";

const TextField = ({
  getInputClasses,
  errors,
  touched,
  name,
  required,
  description,
  type
}) => {
  const setInputFilter = e => {
    let key = e.keyCode || e.which;
    key = String.fromCharCode(key);

    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
    }
  };

  return (
    <label
      className={`form-group ${getInputClasses(errors, touched, name)} ${
        required ? "required" : ""
      } ${name === "avatar" ? "avatar" : ""}`}
    >
      <h3 className={name}>{description}</h3>
      <Field
        name={name}
        render={({ field, form: { setFieldValue, handleBlur } }) => (
          <input
            {...field}
            type={type === "file" ? "text" : type}
            placeholder={description}
            value={field.value || ""}
            onBlur={e => {
              const val = (e.target.value || "").replace(/\s+/gi, " ");
              setFieldValue(field.name, val.trim());
              handleBlur(e);
            }}
            onKeyPress={e =>
              (type === "tel" || type === "number") && setInputFilter(e)
            }
            onPaste={e =>
              (type === "tel" || type === "number") && setInputFilter(e)
            }
          />
        )}
      />
      <FormError errors={errors} touched={touched} field={name} />
    </label>
  );
};

export default TextField;
