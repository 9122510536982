import { Component } from "react";
import QueryString from "query-string";

class LinkedInPopup extends Component {
  componentDidMount() {
    const params = QueryString.parse(window.location.search);
    if (params.error) {
      const errorMessage =
        params.error_description || "Login failed. Please try again.";
      window.opener &&
        window.opener.postMessage(
          { error: params.error, errorMessage },
          window.location.origin
        );
      if (params.error === "user_cancelled_login") {
        window.close();
      }
    }
    if (params.profileToken) {
      window.opener &&
        window.opener.postMessage(
          { profileToken: params.profileToken },
          window.location.origin
        );
    }
  }
  render() {
    return null;
  }
}

export default LinkedInPopup;
