import React from "react";
import { useTranslation, Trans } from 'react-i18next'

const TermsPopup = ({ closePopup, active }) => {
  const { t } = useTranslation();

  return (
    <div className={`popup popup-terms ${active ? "active" : ""}`}>
      <div className="popup__overlay" onClick={closePopup} />
      <div className="popup__content">
        <div className="popup__header">
          <button onClick={closePopup} className="popup-terms__close">
            {t('terms.close')}
          </button>
          <h2 className="title">{t('terms.title')}</h2>
          <hr />
        </div>
        <div className="popup-terms__text">
          <Trans i18nKey="terms.text"/>
          <button className="popup-terms__agree" onClick={closePopup}>
            {t('terms.confirm')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default TermsPopup;
