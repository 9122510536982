import React from "react";
import Checkbox from "./Checkbox";
import Radio from "./Radio";
import { Field } from "formik";

const OptionsField = ({
  type,
  description,
  options,
  groupName,
  errors,
  touched
}) => (
  <div className="form-screen__block">
    {type === "checkbox" ? (
      <>
        <h2>{description}</h2>
        {options.map((item, num) => (
          <label key={num}>
            <Checkbox name={groupName} value={item.value} />
            <span className={`checkboxmark`} />
            <span className="label-text">{item.name}</span>
          </label>
        ))}
      </>
    ) : type === "radio" ? (
      <>
        <h2>{description}</h2>
        {options.map((item, num) => (
          <label key={num}>
            <Radio name={groupName} value={item.value} />
            <span className={`radiomark`} />
            <span className="label-text">{item.name}</span>
          </label>
        ))}
      </>
    ) : (
      <label className={this.getInputClasses(errors, touched)}>
        <h2>{description}</h2>
        <Field name={groupName} component="select">
          <option value="" hidden>
            {groupName}
          </option>
          {options.map((item, num) => (
            <option value={item.value} label={item.value} key={num} />
          ))}
        </Field>
      </label>
    )}
  </div>
);

export default OptionsField;
