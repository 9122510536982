import axios from "axios";
import { baseUrl } from "../helpers";

export async function getAnswerApi(
  dispatch,
  successType,
  failType,
  companyName,
  location,
  id
) {
  try {
    const answerId = JSON.parse(localStorage.getItem("formId"));
    const savedId = JSON.parse(localStorage.getItem("applicationId"));
    const response =
      id === savedId
        ? await axios.get(
            `${baseUrl}/${companyName}/${location}/apply/${answerId}`
          )
        : {};

    dispatch({
      type: successType,
      payload: response.data.data || {}
    });
    return response;
  } catch (error) {
    dispatch({
      type: failType,
      payload: error
    });
  }
}
