import React from "react";
import { Field } from "formik";

const Checkbox = props => (
  <Field name={props.name}>
    {({ field, form }) => (
      <input
        type="checkbox"
        className="checkbox"
        {...props}
        defaultChecked={field.value.includes(props.value)}
        onChange={() => {
          if (field.value.includes(props.value)) {
            const nextValue = field.value.filter(
              value => value !== props.value
            );
            form.setFieldValue(props.name, nextValue);
          } else {
            const nextValue = field.value.concat(props.value);
            form.setFieldValue(props.name, nextValue);
          }
        }}
      />
    )}
  </Field>
);

export default Checkbox;
