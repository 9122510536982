import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation, Trans } from 'react-i18next';
import FBIco from "../assets/img/facebook_ico.svg";
import LNIco from "../assets/img/linkedIn_ico.svg";
import DBIco from "../assets/img/dropbox_ico.svg";
import GDIco from "../assets/img/drive_ico.svg";
import storageIco from "../assets/img/upload.svg";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import DropboxChooser from "react-dropbox-chooser";
import GooglePicker from "react-google-picker";
import { getGoogleFile } from "../services/googleFile";
import updateAvatar from "../services/updateAvatar";
import {
  facebookAppId,
  dropBoxId,
  googleClientId,
  googleDeveloperKey
} from "../helpers";

class PhotoPopup extends Component {
  state = {
    uploadedImage: "",
    uploadedFrom: "",
    uploadedImageName: "",
    submittedImage: "",
    localPhoto: "",
    GDAuthToken: "",
    linkedInCheck: false,
    showFBPhotos: false,
    savedValue: "",
    currentChecked: "",
    lastChecked: ""
  };

  componentDidMount() {
    this.setState({
      linkedInCheck: !this.state.uploadedImage && this.props.linkedInStatus,
      showFBPhotos: this.props.match.params.status && true
    });
  }

  handleRadioCheck = e => {
    const { value } = e.target;
    this.setState({ uploadedImage: value, submittedImage: value });
    this.props.selectPopupPhoto("uploaded");
    this._localUploadEl.checked = true;
  };

  localPhotoHandler = e => {
    this.setState({ localPhoto: e.target.value });
    const reader = new FileReader();
    const file = e.target.files[0];
    const fileName = file.name;

    reader.onload = e => {
      this.props.selectPopupPhoto("uploaded");
      this._localUploadEl.checked = true;
      this.setState({
        uploadedImage: e.target.result,
        submittedImage: e.target.result,
        uploadedFrom: "uploaded",
        uploadedImageName: fileName,
        currentChecked: this._localUploadEl
      });
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  onDBSuccess = file => {
    this.setState({
      uploadedImage: file[0].thumbnailLink,
      submittedImage: file[0].thumbnailLink,
      uploadedFrom: "uploaded",
      uploadedImageName: file[0].name,
      currentChecked: this._localUploadEl
    });
    this.props.selectPopupPhoto("uploaded");
    this._localUploadEl.checked = true;
  };

  onGDSuccess = data => {
    data.docs &&
      getGoogleFile(data.docs[0].id, this.state.GDAuthToken).then(res => {
        this.setState({
          uploadedImage: res.data.thumbnailLink,
          submittedImage: res.data.thumbnailLink,
          uploadedFrom: "uploaded",
          uploadedImageName: res.data.title,
          currentChecked: this._localUploadEl
        });
        this.props.selectPopupPhoto("uploaded");
        this._localUploadEl.checked = true;
      });
  };

  getLocalUploadRef = node => {
    this._localUploadEl = node;
  };

  getLinkedInUploadRef = node => {
    this._LinkedInUploadEl = node;
  };

  getFacebookUploadRef = node => {
    this._facebookUploadEl = node;
  };

  facebookPhotosToggle = () => {
    this.setState({ showFBPhotos: !this.state.showFBPhotos });
  };

  selectPhotoHandler = (e, network) => {
    if (e.target.type === "radio") {
      this.setState({
        submittedImage: e.target.value,
        uploadedImage: e.target.value || this.state.uploadedImage,
        uploadedFrom: network,
        localPhoto: "",
        currentChecked: e.target
      });
    }
  };

  confirmationHandler = () => {
    const {
      companyName,
      city,
      id,
      closePopup,
      allowAvatarUpdating,
    } = this.props;
    allowAvatarUpdating &&
      updateAvatar(
        companyName,
        city,
        id,
        this.state.uploadedImage,
        this.state.uploadedImageName
      );
    this.props.setPopupPhoto(this.state.submittedImage);
    this.setState({ lastChecked: this.state.currentChecked })
    closePopup();
  };

  cancelHandler = () => {
    const {facebookStatus, showFacebookPhoto, linkedInStatus, showLinkedInPhoto} = this.props
    this.props.closePopup();

    if (facebookStatus && showFacebookPhoto) {
      this._facebookUploadEl.checked = true
    }

    if (linkedInStatus && showLinkedInPhoto) {
      this._LinkedInUploadEl.checked = true
    }

    if (this.state.lastChecked) {
      this.state.lastChecked.checked = true
    } else {
      this.state.currentChecked.checked = false
    }
  }

  render() {
    const {
      active,
      closePopup,
      showSocialUploads,
      callSocialUploads,
      responseFacebook,
      photosList,
      linkedInPhoto,
      facebookPhoto,
      facebookStatus,
      linkedInStatus,
      showLinkedInPhoto,
      showFacebookPhoto,
      showUploadedImage,
      wrapperClasses,
      t
    } = this.props;

    const { uploadedImage, showFBPhotos } = this.state;

    return (
      <div
        className={`popup ${active ? "active" : ""} ${wrapperClasses || ""}`}
      >
        <div className="popup__overlay" onClick={closePopup} />
        <div className="popup__content">
          <h3 className="popup__title">
            <Trans i18nKey="photo_popup.title"/>
          </h3>
          <form className={`popup__tab ${!showFBPhotos && "active"}`} id="popup-form">
            <div className="popup__variants">
              {linkedInStatus && showLinkedInPhoto && (
                <label
                  className="popup__profile"
                  onClick={e => this.selectPhotoHandler(e, "LinkedIn")}
                >
                  <input
                    className="popup__radio"
                    type="radio"
                    name="select_photo"
                    value={linkedInPhoto}
                    defaultChecked={!uploadedImage && linkedInStatus}
                    ref={this.getLinkedInUploadRef}
                  />
                  <span className="popup__profile-image">
                    <img src={linkedInPhoto || ""} alt="" />
                  </span>
                  <p className="popup__profile-text">
                    <Trans i18nKey="photo_popup.profile_text" values={{network:"LinkedIn"}}/>
                  </p>
                  <p className="popup__profile-text selected">
                    <Trans i18nKey="photo_popup.profile_text_selected" values={{network:"LinkedIn"}}/>
                  </p>
                  <span className="popup__checked-status" />
                </label>
              )}
              {facebookStatus && showFacebookPhoto && (
                <label
                  className="popup__profile"
                  onClick={e => this.selectPhotoHandler(e, "Facebook")}
                >
                  <input
                    className="popup__radio"
                    type="radio"
                    name="select_photo"
                    value={facebookPhoto}
                    defaultChecked={
                      !uploadedImage && !linkedInStatus && facebookStatus
                    }
                    ref={this.getFacebookUploadRef}
                  />
                  <span className="popup__profile-image">
                    <img src={facebookPhoto || ""} alt="" />
                  </span>
                  <p className="popup__profile-text">
                    <Trans i18nKey="photo_popup.profile_text" values={{network:"Facebook"}}/>
                  </p>
                  <p className="popup__profile-text selected">
                    <Trans i18nKey="photo_popup.profile_text_selected" values={{network:"Facebook"}}/>
                  </p>
                  <span className="popup__checked-status" />
                </label>
              )}
              {showUploadedImage && (
                <label
                  className={`popup__profile`}
                  onClick={e => this.selectPhotoHandler(e, "uploaded")}
                >
                  <input
                    className="popup__radio"
                    type="radio"
                    name="select_photo"
                    value={uploadedImage}
                    ref={this.getLocalUploadRef}
                  />
                  <span className="popup__profile-image">
                    <img src={uploadedImage || ""} alt="" />
                  </span>
                  <p className="popup__profile-text">
                    <Trans i18nKey="photo_popup.profile_text-uploaded"/>
                  </p>
                  <p className="popup__profile-text selected">
                    <Trans i18nKey="photo_popup.profile_text_selected-uploaded"/>
                  </p>
                  <span className="popup__checked-status" />
                </label>
              )}
            </div>
            <div className={`popup__upload ${showSocialUploads ? "show" : ""}`}>
              <div
                className="popup__upload-socials"
                onClick={callSocialUploads}
              >
                <p className="popup__upload-text">Upload from</p>
                <div className="popup__upload-list">
                  <img src={LNIco} alt="" className="popup__upload-icon" />
                  <img src={FBIco} alt="" className="popup__upload-icon" />
                  <img src={DBIco} alt="" className="popup__upload-icon" />
                  <img src={GDIco} alt="" className="popup__upload-icon" />
                </div>
              </div>
              <div
                className="popup__upload-item social social-li"
                onClick={this.props.handleConnectLinkedInClick}
              >
                <div className="popup__upload-icon">
                  <img src={LNIco} alt="" />
                </div>
                <p className="popup__upload-text">{t('photo_popup.upload_from_network', {network: "LinkedIn"})}</p>
              </div>
              {!facebookStatus ? (
                <FacebookLogin
                  disableMobileRedirect={true}
                  appId={facebookAppId}
                  autoLoad={false}
                  fields="picture.width(100).height(100),first_name,last_name,photos,email"
                  scope="public_profile,email,user_photos"
                  callback={response => {
                    if (response.id) {
                      responseFacebook(response);
                      this.facebookPhotosToggle();
                    }
                  }}
                  redirectUri={`${window.location.href}open_images`}
                  render={renderProps => (
                    <div
                      className="popup__upload-item social social-fb"
                      onClick={renderProps.onClick}
                    >
                      <div className="popup__upload-icon">
                        <img src={FBIco} alt="" />
                      </div>
                      <p className="popup__upload-text">{t('photo_popup.upload_from_network', {network: "Facebook"})}</p>
                    </div>
                  )}
                />
              ) : (
                <div
                  className="popup__upload-item social social-fb"
                  onClick={this.facebookPhotosToggle}
                >
                  <div className="popup__upload-icon">
                    <img src={FBIco} alt="" />
                  </div>
                  <p className="popup__upload-text">{t('photo_popup.upload_from_network', {network: "Facebook"})}</p>
                </div>
              )}
              <DropboxChooser
                appKey={dropBoxId}
                success={file => this.onDBSuccess(file)}
                multiselect={false}
                extensions={[".png", ".jpg", ".jpeg"]}
              >
                <div className="popup__upload-item social social-db">
                  <div className="popup__upload-icon">
                    <img src={DBIco} alt="" />
                  </div>
                  <p className="popup__upload-text">{t('photo_popup.upload_from_network', {network: "Dropbox"})}</p>
                </div>
              </DropboxChooser>
              <GooglePicker
                clientId={googleClientId}
                developerKey={googleDeveloperKey}
                scope={["https://www.googleapis.com/auth/drive.file"]}
                onChange={data => this.onGDSuccess(data)}
                onAuthenticate={token => this.setState({ GDAuthToken: token })}
                onAuthFailed={data => console.log("on auth failed:", data)}
                navHidden={true}
                authImmediate={false}
                mimeTypes={["image/png", "image/jpeg", "image/jpg"]}
                viewId={"DOCS_IMAGES"}
              >
                <div className="popup__upload-item social social-gd">
                  <div className="popup__upload-icon">
                    <img src={GDIco} alt="" />
                  </div>
                  <p className="popup__upload-text">{t('photo_popup.upload_from_network', {network: "GoogleDrive"})}</p>
                </div>
              </GooglePicker>
              <label className="popup__upload-item">
                <input
                  type="file"
                  name="local_photo"
                  accept=".png, .jpg"
                  onChange={this.localPhotoHandler}
                  value={this.state.localPhoto}
                />
                <div className="popup__upload-icon">
                  <img src={storageIco} alt="" />
                </div>
                <p className="popup__upload-text">{t('photo_popup.upload_from_storage')}</p>
              </label>
            </div>
            <label
              className="popup__no-photo"
              onClick={e => this.selectPhotoHandler(e, "uploaded")}
            >
              <input
                className="popup__radio"
                type="radio"
                name="select_photo"
                value=""
              />
              <p className="popup__no-photo-text">
                {t('photo_popup.dont_use_title')}
                <span>{t('photo_popup.dont_use_text')}</span>
              </p>
              <span className="popup__checked-status" />
            </label>
          </form>
          <form
            className={`popup__tab popup__fb ${showFBPhotos &&
              photosList.length &&
              "active"}`}
            onSubmit={e => {
              this.facebookPhotosToggle();
              e.preventDefault();
            }}
          >
            {showFBPhotos &&
              photosList &&
              photosList.map((item, index) => (
                <label key={index} className="popup__fb-photo">
                  <input
                    type="radio"
                    name="facebook_photo"
                    value={item.images[item.images.length - 1].source}
                    onChange={this.handleRadioCheck}
                    defaultChecked={
                      item.images[item.images.length - 1].source ===
                      uploadedImage
                    }
                  />
                  <span className="popup__fb-status" />
                  <img
                    src={item.images[item.images.length - 1].source}
                    alt=""
                  />
                </label>
              ))}
            <div className="popup__tab-title">
              <button className="popup__tab-button">{t('photo_popup.check_facebook_photo')}</button>
            </div>
            <p className="popup__tab-text">
              {t('photo_popup.text_facebook_photo')}
            </p>
          </form>
          <div className="popup__controlsContainer">
            <button className="popup__cancel" onClick={this.cancelHandler}>
              {t('photo_popup.buttons.cancel')}
            </button>
            <button
              className="popup__confirm"
              onClick={this.confirmationHandler}
            >
              {t('photo_popup.buttons.confirm')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(PhotoPopup));
