import React from "react";
import { Field } from "formik";

const TextAreaField = ({
  getInputClasses,
  errors,
  touched,
  name,
  required,
  description,
  type
}) => (
  <div
    className={`form-screen__block textarea ${getInputClasses(
      errors,
      touched,
      name
    )} ${required ? "required" : ""}`}
  >
    <h2>{description}</h2>
    <Field
      name={name}
      placeholder=""
      component={type}
      rows="1"
      maxLength="255"
    />
  </div>
);

export default TextAreaField;
