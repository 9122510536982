import axios from "axios";
import { baseUrl } from "../helpers";

export const handleSuccess = data => {
  return axios
    .post(`${baseUrl}/linkedIn/getProfile?profileToken=${data.profileToken}`)
    .then(res => {
      console.log(res);
      const dataCorrectFields = {};
      dataCorrectFields.first_name =
        res.data.firstName.localized[
          Object.keys(res.data.firstName.localized)[0]
        ];
      dataCorrectFields.last_name =
        res.data.lastName.localized[
          Object.keys(res.data.lastName.localized)[0]
        ];
      dataCorrectFields.avatar =
        res.data.profilePicture &&
        res.data.profilePicture[0].identifiers[0].identifier;
      dataCorrectFields.profileUrl = `https://linkedin.com/in/${
        res.data.vanityName
      }`;
      return dataCorrectFields;
    });
};
