import React from "react";
import { Field } from "formik";

const Radio = props => (
  <Field name={props.name}>
    {({ field, form }) => (
      <input
        type="radio"
        className="radio"
        {...props}
        defaultChecked={field.value.includes(props.value)}
        onChange={() => form.setFieldValue(props.name, props.value)}
      />
    )}
  </Field>
);

export default Radio;
