import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "../history";
import { connect } from "react-redux";
import JobDescription from "../components/JobDescription";
import ApplyForm from "../components/ApplyForm";
import ApplyFormStep2 from "../components/ApplyFormStep2";
import Confirmation from "../components/Confirmation";
import NotFound from "../components/NotFound";
import { getDescription } from "../actions/DescriptionActions";
import { getAnswer } from "../actions/AnswerActions";
import { handleSuccess } from "../services/LinkedInProfile";
import { sendForm } from "../services/FormData";
import { baseUrl } from "../helpers";

class Container extends Component {
  state = {
    userFacebookInfo: null,
    userLinkedInInfo: null,
    profileToken: "",
    linkedInStatus: false,
    facebookStatus: false,
    showLinkedInPhoto: false,
    showFacebookPhoto: false,
    showUploadedImage: false,
    selectedPhoto: "",
    firstStepFormData: null,
    userName: "",
    facebookPictures: [],
    selectedSN: "",
    savedFormData: null,
    CVFileName: ""
  };

  goToForm = () => {
    let { url } = this.props.match;
    url = url.slice(-1) === "/" ? url.slice(0, url.length - 1) : url;

    history.push(`${url}/apply/`);
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleLinkedInSuccess = data => {
    handleSuccess(data).then(res => {
      this.setState({
        profileToken: data.profileToken,
        errorMessage: "",
        userLinkedInInfo: res,
        linkedInStatus: true
      });
      this.selectPopupPhoto("LinkedIn");
      this.setPopupPhoto(res.avatar);
      this.props.match.isExact && this.goToForm();
      localStorage.setItem("linkedInAuth", "true");
    });
  };

  handleLinkedInFailure = () => {
    this.setState({ profileToken: "" });
  };

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveMessage, false);
    if (this.popup && !this.popup.closed) this.popup.close();
  }

  componentDidMount() {
    const { url } = this.props.match;
    const { company, location, jobData } = this.props.match.params;
    const id = jobData.slice(jobData.lastIndexOf("-") + 1);
    this.props.getDescriptionAction(url);
    this.props.getAnswerAction(company, location, id);
    JSON.parse(localStorage.getItem("linkedInAuth")) &&
      this.handleConnectLinkedInClick();
  }

  responseFacebook = response => {
    if (response.id) {
      localStorage.setItem("facebookAuth", "true");
      this.setState({
        userFacebookInfo: response,
        facebookStatus: true
      });
      if (!this.state.linkedInStatus) {
        this.selectPopupPhoto("Facebook");
        this.setPopupPhoto(response.picture.data.url);
      }
      window.FB.api(
        `/me?fields=albums.limit(5){photos{images}}`,
        response =>
          response &&
          !response.error &&
          this.setState({
            facebookPictures: response.albums.data[0].photos.data
          })
      );
    }
  };

  receiveMessage = event => {
    if (event.origin === window.location.origin) {
      if (event.data.errorMessage) {
        this.handleLinkedInFailure();
        this.popup && this.popup.close();
      } else if (event.data.profileToken) {
        this.handleLinkedInSuccess({ profileToken: event.data.profileToken });
        this.popup && this.popup.close();
      }
    }
  };

  handleConnectLinkedInClick = () => {
    this.popup = window.open(
      `${baseUrl}/auth/linkedIn`,
      "_blank",
      "width=600,height=600"
    );
    window.removeEventListener("message", this.receiveMessage, false);
    window.addEventListener("message", this.receiveMessage, false);
  };

  firstStepFormHandler = values => {
    let { url } = this.props.match;
    const newUrl = url.slice(-1) === "/" ? url.slice(0, url.length - 1) : url;
    let userName = "";
    const unique = this.removeDuplicates(values, "name");
    unique.map(el => (userName += el.name === "name" ? el.value : ""));
    this.setState({ firstStepFormData: unique, userName });
    this.props.data.description.steps[2]
      ? history.push(`${newUrl}/questions`)
      : this.sendForm(unique);
  };

  removeDuplicates = (originalArray, prop) => {
    const newArray = [];
    const lookupObject = {};

    originalArray.map(item => (lookupObject[item[prop]] = item));

    Object.values(lookupObject).map(item => newArray.push(item));

    return newArray;
  };

  sendForm = values => {
    let { url } = this.props.match;
    url = url.slice(-1) === "/" ? url.slice(0, url.length - 1) : url;
    const { company, location, jobData } = this.props.match.params;
    const appId = jobData.slice(jobData.lastIndexOf("-") + 1);
    const formData = {};
    const { firstStepFormData, userLinkedInInfo } = this.state;
    const arr = firstStepFormData ? values.concat(firstStepFormData) : values;
    arr.forEach(el => {
      el.value = typeof el.value === "number" ? el.value.toString() : el.value;
    });
    formData["applicationFormId"] = appId;
    formData["formData"] = this.removeDuplicates(arr, "name");
    formData["userData"] = {
      linkedInProfileUrl: userLinkedInInfo ? userLinkedInInfo.profileUrl : null
    };

    sendForm(company, location, formData)
      .then(res => {
        localStorage.setItem("formId", JSON.stringify(res.data.id));
        localStorage.setItem("applicationId", JSON.stringify(appId));
        history.push(`${url}/thanks`);
      })
      .catch(err => console.log(err));
  };

  selectPopupPhoto = network => {
    const state = {
      showLinkedInPhoto: false,
      showFacebookPhoto: false,
      showUploadedImage: false
    };

    if (network === "Facebook") {
      state.showFacebookPhoto = true;
    } else if (network === "LinkedIn") {
      state.showLinkedInPhoto = true;
    } else if (network === "uploaded") {
      state.showUploadedImage = true;
    } else {
      state.showFacebookPhoto = this.state.showFacebookPhoto;
      state.showLinkedInPhoto = this.state.showLinkedInPhoto;
      state.showUploadedImage = this.state.showUploadedImage;
    }

    this.setState({
      selectedSN: network,
      ...state
    });
  };

  setPopupPhoto = value => {
    this.setState({ selectedPhoto: value });
  };

  setCVName = name => {
    this.setState({ CVFileName: name });
  };

  cloneObject = object => {
    return JSON.parse(JSON.stringify(object));
  };

  render() {
    const { description, isFetching, error } = this.props.data;

    const answer = this.cloneObject(this.props.answer.data);
    if (answer) {
      const CVIndex = answer.findIndex(el => el.name === "CV");
      answer.splice(CVIndex, CVIndex - 1);
    }

    const {
      userLinkedInInfo,
      userFacebookInfo,
      savedFormData,
      userName
    } = this.state;

    const firstStepFormData = this.cloneObject(this.state.firstStepFormData);

    let { url } = this.props.match;
    url = url.slice(-1) === "/" ? url.slice(0, url.length - 1) : url;

    const urlArray = window.location.pathname.split("/");
    const currentPath = urlArray[urlArray.length - 1];

    return (
      <Router history={history}>
        <>
          {isFetching || this.props.answer.isFetching ? (
            <div className="loading">
              <div className="loading__container">
                <div className="loading__title">Loading</div>
                <div className="spinner">
                  <span />
                </div>
              </div>
            </div>
          ) : description && typeof answer === "object" ? (
            <Switch>
              <Route
                exact
                path={`${url}/`}
                render={props => (
                  <JobDescription
                    {...props}
                    goToForm={this.goToForm}
                    responseFacebook={this.responseFacebook}
                    handleConnectLinkedInClick={this.handleConnectLinkedInClick}
                    description={description.info}
                  />
                )}
              />
              <Route
                path={`${url}/apply/:status?`}
                render={props => (
                  <ApplyForm
                    {...props}
                    answer={answer}
                    savedFormData={savedFormData && savedFormData}
                    photosList={this.state.facebookPictures}
                    selectedPhoto={this.state.selectedPhoto}
                    selectPopupPhoto={this.selectPopupPhoto}
                    setPopupPhoto={this.setPopupPhoto}
                    selectedSN={this.state.selectedSN}
                    firstStepFormHandler={this.firstStepFormHandler}
                    firstStepFormData={firstStepFormData}
                    responseFacebook={this.responseFacebook}
                    handleConnectLinkedInClick={this.handleConnectLinkedInClick}
                    fields={description.steps["1"]}
                    secondStepExist={description.steps["2"]}
                    userInfo={userLinkedInInfo || userFacebookInfo}
                    facebookAvatar={
                      userFacebookInfo && userFacebookInfo.picture
                    }
                    linkedInStatus={this.state.linkedInStatus}
                    facebookStatus={this.state.facebookStatus}
                    showLinkedInPhoto={this.state.showLinkedInPhoto}
                    showFacebookPhoto={this.state.showFacebookPhoto}
                    showUploadedImage={this.state.showUploadedImage}
                    description={description.info}
                    CVFileName={this.state.CVFileName}
                    setCVName={this.setCVName}
                  />
                )}
              />
              <Route
                path={`${url}/questions`}
                render={props => (
                  <ApplyFormStep2
                    {...props}
                    answer={answer}
                    savedFormData={savedFormData && savedFormData}
                    selectedPhoto={this.state.selectedPhoto}
                    selectPopupPhoto={this.selectPopupPhoto}
                    setPopupPhoto={this.setPopupPhoto}
                    sendForm={this.sendForm}
                    goToForm={this.goToForm}
                    userInfo={userLinkedInInfo || userFacebookInfo}
                    userName={userName}
                    description={description.info}
                    fields={description.steps["2"]}
                  />
                )}
              />
              <Route
                path={`${url}/thanks`}
                render={props => (
                  <Confirmation
                    {...props}
                    answer={answer}
                    params={this.props.match.params}
                    goToForm={this.goToForm}
                    selectedPhoto={this.state.selectedPhoto}
                    selectPopupPhoto={this.selectPopupPhoto}
                    setPopupPhoto={this.setPopupPhoto}
                    userName={userName}
                    description={description.info}
                    responseFacebook={this.responseFacebook}
                    userInfo={userLinkedInInfo || userFacebookInfo}
                    facebookAvatar={
                      userFacebookInfo && userFacebookInfo.picture
                    }
                    linkedInStatus={this.state.linkedInStatus}
                    facebookStatus={this.state.facebookStatus}
                    handleConnectLinkedInClick={this.handleConnectLinkedInClick}
                    photosList={this.state.facebookPictures}
                    showLinkedInPhoto={this.state.showLinkedInPhoto}
                    showFacebookPhoto={this.state.showFacebookPhoto}
                    showUploadedImage={this.state.showUploadedImage}
                  />
                )}
              />
              <Route
                render={props => <NotFound {...props} goBack={this.goBack} />}
              />
            </Switch>
          ) : (
            <div className={`error-block ${currentPath} full-width`}>
              <p className="error-block__text">{error.message}</p>
              <a href="" className="error-block__retry">
                Retry
              </a>
            </div>
          )}
        </>
      </Router>
    );
  }
}

const mapStateToProps = store => ({
  data: store.data,
  answer: store.answer
});
const mapDispatchToProps = dispatch => ({
  getDescriptionAction: url => dispatch(getDescription(url)),
  getAnswerAction: (companyName, location, currentId) =>
    dispatch(getAnswer(companyName, location, currentId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);
